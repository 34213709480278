import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'gatsby';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';

import { CursorContext, CursorTypes } from '@providers/CursorProvider';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';
import { Container } from '@components/common/Container';
import { AnimatedLine } from '@components/common/AnimatedLine';

import animationData from './NotFoundCat.json';
import * as s from './ContentBlock.module.sass';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'none',
  },
};

type ContentBlockProps = {
  contentHeight?: number
};

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const ContentBlock: React.FC<ContentBlockProps> = ({
  contentHeight = 0,
}) => {
  const { toggleCursorType } = useContext(CursorContext);
  const { colorThemeMode } = useContext(ColorThemeContext);

  return (
    <div className={modeClass[colorThemeMode]}>
      <Container>
        <AnimatedLine duration={1.2} delay={1} fromStart />

        <div className={s.content} style={{ minHeight: `${contentHeight}px` }}>
          <div className={s.animationWrapper}>
            <div className={s.animation}>
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <div className={s.text}>
            <AnimatedLine duration={1} delay={1.4} fromStart />
            <div className={s.headerWrapper}>
              <AnimatedLine duration={1} delay={1.6} fromStart direction='horizontal' />
              <motion.h1
                className={s.header}
                initial={{ opacity: 0, y: 80 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1,
                  delay: 2,
                }}
              >
                Page Not Found!
              </motion.h1>
              <AnimatedLine duration={1} delay={2.6} fromStart direction='horizontal' verticalAlign='bottom' />
            </div>
            <div className={s.descriptionWrapper}>
              <motion.p
                initial={{ opacity: 0, y: 80 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1,
                  delay: 3,
                }}
              >
                We&apos;re unable to find the page you&apos;re looking for.
              </motion.p>

              <motion.div
                initial={{ opacity: 0, y: 80 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{
                  ease: 'easeInOut',
                  duration: 1,
                  delay: 3.4,
                }}
              >
                <Link
                  to='/'
                  onMouseEnter={() => toggleCursorType(CursorTypes.pointer)}
                  onMouseLeave={() => toggleCursorType(CursorTypes.default)}
                >
                <span className={s.backArrows}>
                  {'<<<'}
                </span>
                  Back to home
                </Link>
              </motion.div>
            </div>
          </div>
        </div>

        <AnimatedLine duration={1.2} delay={1.3} fromStart align='right' className={s.mobile} />
      </Container>
    </div>
  );
};