// extracted by mini-css-extract-plugin
export var backWrapper = "ContentBlock-module--backWrapper--39h1K";
export var backArrows = "ContentBlock-module--backArrows--1yxex";
export var buttonArrow = "ContentBlock-module--buttonArrow--2bV-U";
export var content = "ContentBlock-module--content--2J1Wh";
export var animationWrapper = "ContentBlock-module--animationWrapper--F9eRh";
export var animation = "ContentBlock-module--animation--2YZgl";
export var text = "ContentBlock-module--text--1dE8n";
export var headerWrapper = "ContentBlock-module--headerWrapper--2DVNe";
export var descriptionWrapper = "ContentBlock-module--descriptionWrapper--1AW7e";
export var header = "ContentBlock-module--header--1oIX9";
export var dark = "ContentBlock-module--dark--33nAK";
export var mobile = "ContentBlock-module--mobile--3NLK4";