import * as React from 'react';
import { PageProps } from 'gatsby';
import { useEffect, useRef, useState } from 'react';

import { debounce } from '@utils/helpers';
import { BaseLayout } from '@layouts/BaseLayout';
import { ContentBlock } from '@components/notFound/ContentBlock';

const NotFoundPage: React.FC<PageProps> = () => {
  const footerRef = useRef<HTMLElement | null>(null);

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    // prevents flashing
    const debouncedHandleResize = debounce(() => {
      const footer = footerRef.current;
      if (footer) {
        setContentHeight(window.innerHeight - footer.offsetHeight);
      }
    }, 1000);
    debouncedHandleResize();

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return (
    <BaseLayout
      seo={{
        title: '404: Page Not Found | Ilona K',
        description: 'Ilona K: 404: Page Not Found',
      }}
      hiddenHeader
      footerRef={footerRef}
    >
      <ContentBlock contentHeight={contentHeight} />
    </BaseLayout>
  );
};

export default NotFoundPage;
